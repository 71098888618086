import React, { useState, useEffect } from 'react';
import Toast from './Toast';
// import { formatDistanceToNow, format, isAfter, subHours, parseISO } from 'date-fns';

const SECRETS_PER_PAGE = 10;

function Secrets() {
  const [secrets, setSecrets] = useState([]);
  const [newTitle, setNewTitle] = useState('');
  const [newSecret, setNewSecret] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [toast, setToast] = useState(null);

  useEffect(() => {
    const storedSecrets = JSON.parse(localStorage.getItem('secrets') || '[]');
    setSecrets(storedSecrets);
  }, []);

  const saveSecrets = (updatedSecrets) => {
    localStorage.setItem('secrets', JSON.stringify(updatedSecrets));
    setSecrets(updatedSecrets);
  };

  const addSecret = (e) => {
    e.preventDefault();
    if (!newTitle.trim() || !newSecret.trim()) return;
    const secretObject = {
      id: Date.now(),
      title: newTitle,
      secret: newSecret,
      createdAt: new Date().toISOString(),
      isVisible: false
    };
    saveSecrets([secretObject, ...secrets]);
    setNewTitle('');
    setNewSecret('');
    setCurrentPage(1);
  };

  const deleteSecret = (id) => {
    const updatedSecrets = secrets.filter(secret => secret.id !== id);
    saveSecrets(updatedSecrets);
    if (currentPage > Math.ceil(updatedSecrets.length / SECRETS_PER_PAGE)) {
      setCurrentPage(prev => prev - 1);
    }
  };

  const toggleVisibility = (id) => {
    saveSecrets(secrets.map(secret => 
      secret.id === id ? { ...secret, isVisible: !secret.isVisible } : secret
    ));
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setToast('Copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
      setToast('Failed to copy');
    });
  };

//   const formatTimestamp = (timestamp) => {
//     try {
//       const date = parseISO(timestamp);
//       const now = new Date();
//       const twoHoursAgo = subHours(now, 2);

//       if (isAfter(date, twoHoursAgo)) {
//         return formatDistanceToNow(date, { addSuffix: true });
//       } else {
//         return format(date, 'dd/MM/yyyy hh:mm a');
//       }
//     } catch (error) {
//       console.error('Error formatting date:', error);
//       return 'Invalid date';
//     }
//   };

  const pageCount = Math.ceil(secrets.length / SECRETS_PER_PAGE);
  const paginatedSecrets = secrets.slice(
    (currentPage - 1) * SECRETS_PER_PAGE,
    currentPage * SECRETS_PER_PAGE
  );

  return (
    <div className="secrets-container">
      <h1>My Secrets</h1>
      <form onSubmit={addSecret}>
        <input
          type="text"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
          placeholder="Enter secret title"
        />
        <textarea
          value={newSecret}
          onChange={(e) => setNewSecret(e.target.value)}
          placeholder="Enter your secret"
          rows="3"
        />
        <button type="submit">Add Secret</button>
      </form>
      <table className="secrets-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Secret</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {paginatedSecrets.map(secret => (
            <tr key={secret.id}>
              <td>{secret.title}</td>
              <td>
                {secret.isVisible ? secret.secret : 'xxxxxxx'}
                {/* <span className="secret-timestamp">{formatTimestamp(secret.createdAt)}</span> */}
              </td>
              <td>
                <button onClick={() => toggleVisibility(secret.id)} className="visibility-btn" aria-label="Toggle secret visibility">
                  {secret.isVisible ? '🙈' : '👁️'}
                </button>
                <button onClick={() => copyToClipboard(secret.secret)} className="copy-btn" aria-label="Copy secret">
                  📋
                </button>
                <button onClick={() => deleteSecret(secret.id)} className="delete-btn" aria-label="Delete secret">
                  🗑️
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {pageCount > 1 && (
        <div className="pagination">
          <button 
            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>{currentPage} / {pageCount}</span>
          <button 
            onClick={() => setCurrentPage(prev => Math.min(prev + 1, pageCount))}
            disabled={currentPage === pageCount}
          >
            Next
          </button>
        </div>
      )}
      {/* ... (keep the pagination as it was) */}
      {toast && (
        <Toast 
          message={toast} 
          onClose={() => setToast(null)}
        />
      )}
    </div>
  );
}

export default Secrets;
// src/components/Footer.js
import React, { useState } from 'react';
import ToastMessage from './ToastMessage';

function Footer() {
  const [email, setEmail] = useState('');
  const [toast, setToast] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you would typically handle the subscription logic
    console.log('Subscribing email:', email);
    // Show toast message
    setToast('Thanks for subscribing!');
    // Reset the email input after submission
    setEmail('');
  };

  return (
    <footer className="site-footer">
      <div className="footer-content">
        <form onSubmit={handleSubmit} className="subscribe-form">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email address"
            required
          />
          <button type="submit">Subscribe</button>
        </form>
      </div>
      {toast && (
        <ToastMessage 
          message={toast} 
          onClose={() => setToast(null)}
        />
      )}
    </footer>
  );
}

export default Footer;
// src/hooks/useHideSideMenu.js
export const useHideSideMenu = (path) => {
    // Add patterns here for routes where you want to hide the side menu
    const hidePatterns = [
      /^\/blog\/.+/,  // Matches /blog/:blog_key
      /^\/blog\/archives$/,  // Matches /blog/archives exactly
      /^\/archives$/,  // Matches /archives exactly
      // Add more patterns as needed
      /^\/links$/,  // Matches /archives exactly
    ];
  
    return hidePatterns.some(pattern => pattern.test(path));
  };
export const ROUTES = [
    {
      name: 'About',
      path: '/about',
      description: 'Information about Fitness With Sipu',
      displayTitle: 'About Fitness With Sipu'
    },
    {
      name: 'Advice',
      path: '/advice',
      description: 'Advice from Fitness With Sipu',
      displayTitle: 'Advice'
    },
    {
      name: 'Coding',
      path: '/coding',
      description: 'Basics of Software Development',
      displayTitle: 'coding'
    },
    {
      name: 'Fomo',
      path: '/fomo',
      description: 'Understanding Fomo',
      displayTitle: 'fomo'
    },
    {
      name: 'Communication',
      path: '/communication',
      description: 'Art of Communication',
      displayTitle: 'communication'
    },
    // ... add more routes as needed
    {
        name: 'Notes',
        path: '/n/notes',
        description: 'Personal notes',
        displayTitle: 'My Notes'
    },
    {
        name: 'Secrets',
        path: '/n/secrets',
        description: 'Secrets/password',
        displayTitle: 'Secret Manager'
    },
    {
      name: 'Links',
      path: '/links',
      description: 'Bookmarks',
      displayTitle: 'Bookmarks'
  }
  ];
  
  export const HOME_ROUTE = {
    name: 'Home',
    path: '/',
    description: 'Fitness With Sipu\'s homepage',
    displayTitle: 'Fitness With Sipu'
  };
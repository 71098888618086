import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import SideMenu from './components/SideMenu';
import MainContent from './components/MainContent';
import Notes from './components/Notes';
import Secrets from './components/Secrets';
import { ROUTES, HOME_ROUTE } from './constants/routes';
// import { PRIVATE_ROUTES } from './constants/private_route';
import homeData from './data/home.json';
import './styles.css';
import { useHideSideMenu } from './hooks/useHideSideMenu';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAlDtRwk7b-rh_9v6cQ6AuAlymP_CzP_pQ",
  authDomain: "latika-dev.firebaseapp.com",
  projectId: "latika-dev",
  storageBucket: "latika-dev.appspot.com",
  messagingSenderId: "90165971711",
  appId: "1:90165971711:web:32f0a7ccccd60e53575aca",
  measurementId: "G-PXKEX7YH6V"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

function AppContent() {
  const location = useLocation();
  const hideSideMenu = useHideSideMenu(location.pathname);

  return (
    <div className="app-container">
      <main className="main-content">
        <Routes>
          <Route path={HOME_ROUTE.path} element={<MainContent content={homeData} />} />
          <Route path="/:page_title" element={<MainContent />} />
          <Route path="/n/notes" element={<Notes />} />
          <Route path="/n/secrets" element={<Secrets />} />
          <Route path="/f/:page_title" element={<MainContent />} />
          {/* Add any new routes here */}
        </Routes>
      </main>
      {!hideSideMenu && <SideMenu items={ROUTES} />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;